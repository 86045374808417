import { Auth0Client } from '@auth0/auth0-spa-js';

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_AUDIENCE } = process.env;

let auth0: Auth0Client;
export default function getAuth0Client(): Auth0Client {
  if (auth0) {
    return auth0;
  }
  auth0 = new Auth0Client({
    clientId: VITE_AUTH0_CLIENT_ID!,
    domain: VITE_AUTH0_DOMAIN!,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      response_type: 'token id_token code',
      scope: 'openid email profile offline_access',
      audience: VITE_AUTH0_AUDIENCE,
      redirect_uri: window.location.origin
    }
  });
  return auth0;
}
